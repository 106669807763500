import { Icon } from '../Icon'
import { BadgeWrapper } from './Badge.style'

type Props<Text> = {
  text: Text
  fill?: boolean
  border?: boolean
  onRemove?: () => void
  onClick?: () => void
  isActive?: boolean
  className?: string
  isWrappedByLink?: boolean
}

export const Badge = <Text,>({
  text,
  onRemove,
  fill,
  border,
  onClick,
  isActive = true,
  className,
  isWrappedByLink = false,
}: Props<Text>): React.ReactNode | null => {
  if (!text) {
    return null
  }

  return (
    <BadgeWrapper
      $hasFill={fill}
      $hasBorder={border}
      $isClickable={isWrappedByLink || !!onClick}
      onClick={onClick}
      $isActive={isActive}
      className={className}
    >
      {/* @ts-expect-error */}
      <div>{text}</div>
      {onRemove && (
        <Icon
          icon="XSmall"
          alt="remove"
          color="bgSecondary"
          onClick={onRemove}
          style={{ cursor: 'pointer', marginLeft: '5px' }}
        />
      )}
    </BadgeWrapper>
  )
}
