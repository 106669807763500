import { FC } from 'react'
import { getSDGInfo } from '@netpurpose/core'
import { Standard } from '@netpurpose/types'
import { colors } from '../../../themes/light/colors'
import { Badge } from '../../Badge'
import { NextLink as Link } from '../../NextLink'
import { Tooltip } from '../../Tooltip'
import { BadgesContainer } from '../BadgesContainer.style'
import { BadgeWrapper } from './StandardBadge.style'

const getText = (
  standard: Standard,
  withStandardClass: boolean,
  code: 'fullCode' | 'majorCode' = 'fullCode',
): string => {
  const { majorCode, standardClass } = standard
  return `${withStandardClass ? standardClass : ''} ${standard[code] || majorCode}`
}

const getTitle = ({
  disableTooltip,
  standard,
  CustomTooltip,
}: {
  disableTooltip?: boolean
  standard: Standard
  CustomTooltip: ((props: { standard: Standard }) => React.ReactNode) | undefined
}) => {
  if (disableTooltip) {
    return ''
  }

  if (CustomTooltip) {
    return <CustomTooltip standard={standard} />
  }

  return standard.description
}

export const StandardBadge: FC<{
  standard: Standard
  withStandardClass: boolean
  withFill: boolean
  inactive?: boolean
  isLink?: boolean
  disableTooltip?: boolean
  onHover?: () => void
  CustomTooltip?: ((props: { standard: Standard }) => React.ReactNode) | undefined
  code?: 'fullCode' | 'majorCode'
  placement?: 'bottom' | 'right'
}> = ({
  standard,
  withStandardClass,
  withFill,
  inactive = false,
  isLink = true,
  disableTooltip = false,
  onHover,
  CustomTooltip,
  code = 'fullCode',
  placement = 'right',
}) => {
  // Some of the backend endpoints return very minimal data for SDGs
  // So we can use this utility to add in missing data (e.g. colour, description)
  // TODO: we're using this util in a circular way, before we use the StandardBadges
  // component, and then again here within the StandardBadge. We should
  // only need to call it once here.
  const extraStandardInfo = getSDGInfo(standard.majorCode)
  const fullStandard = {
    ...standard,
    ...extraStandardInfo,
    // We don't want to overwrite the target description returned from the BE if
    // we're showing the full code. Otherwise, default to the hardcoded goal-level
    // description.
    description:
      standard.fullCode && standard.description
        ? standard.description
        : extraStandardInfo?.description || '',
  }

  const Content = () => (
    <Tooltip
      placement={placement}
      title={getTitle({ standard: fullStandard, disableTooltip, CustomTooltip })}
      onOpenChange={onHover}
      $withoutPadding={!!CustomTooltip}
      $widerWidth={!!CustomTooltip}
    >
      <BadgeWrapper
        $standardColour={inactive ? colors.typeTertiary : fullStandard.colour}
        $isLink={isLink && !!fullStandard.link}
      >
        <Badge
          text={getText(fullStandard, withStandardClass, code)}
          fill={withFill}
          border={!withFill}
          isWrappedByLink={isLink && !!fullStandard.link}
          isActive={!inactive}
          className="standard-badge"
        />
      </BadgeWrapper>
    </Tooltip>
  )

  return isLink && !!fullStandard.link ? (
    <Link href={fullStandard.link} external>
      <Content />
    </Link>
  ) : (
    <Content />
  )
}

export const StandardBadgeWithoutTooltip: FC<{
  standard: Standard
  withStandardClass: boolean
  withFill: boolean
}> = ({ standard, withStandardClass, withFill }) => (
  <BadgeWrapper $standardColour={standard.colour}>
    <Badge
      text={getText(standard, withStandardClass)}
      fill={withFill}
      border={!withFill}
      isActive
      className="standard-badge"
    />
  </BadgeWrapper>
)

export const StandardsBadges: FC<{
  standards: Standard[]
  withStandardClass?: boolean
  withFill?: boolean
  inline?: boolean
  noPadding?: boolean
  isInactive?: boolean
}> = ({
  standards,
  withStandardClass = true,
  withFill = true,
  inline = false,
  noPadding = false,
  isInactive = false,
}) => {
  return (
    <BadgesContainer
      $numItems={standards.length}
      $inline={inline}
      $noPadding={noPadding}
      $withStandardClass={withStandardClass}
    >
      {standards.map((standard) => (
        <StandardBadge
          key={standard.id}
          standard={standard}
          withStandardClass={withStandardClass}
          withFill={withFill}
          inactive={isInactive}
        />
      ))}
    </BadgesContainer>
  )
}
